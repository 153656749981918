import React from 'react'
import styled from 'styled-components'

import { Wrapper } from '../wrapper'
import { Container } from '../container'
import { Heading } from '../heading'
import { StyledLink } from '../link'

import { device  } from '../../../utils/breakpoints'

import { GatsbyImage } from "gatsby-plugin-image"



const StyledWrapper = styled(Wrapper) `
    background: linear-gradient( #F9F9F9 70%, #40916C 30%);
`


const StyledContainer = styled(Container) `
    gap: 2rem;
    justify-items: center;

    @media ${device.tablet} {
        grid-template-columns: 1fr 1fr;
    }

    .image-title-page {
        grid-row: 2;
        max-width: 500px;
        max-height: 300px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;

        @media ${device.tablet} { 
            grid-row: 1;
            grid-column: 1;
        }
    }

`

const ContainerText = styled.div `
    display: grid;
    justify-items: center;
    align-content: start;
    gap: 2rem;
`

const StyledHeading = styled(Heading) `
    color: ${({theme}) => theme.colors.green};
`   


export const TitlePage = ({ title, text, imageTitle, titleSeoImage, altSeoImage  }) => {

    return (
        <StyledWrapper>
            <StyledContainer>
                <GatsbyImage
                    image={imageTitle}
                    className='image-title-page'
                    alt={altSeoImage}
                    title={titleSeoImage}
                />
                <ContainerText>
                    <StyledHeading h1 as='h1'>{title}</StyledHeading>
                    <p>{text}</p>
                </ContainerText>
            </StyledContainer>
        </StyledWrapper>
    )
}