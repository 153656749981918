import React from 'react'
import styled from 'styled-components'

import { Heading } from '../common/heading'
import { Button } from '../common/button'

import { device } from '../../utils/breakpoints'

import { GatsbyImage } from "gatsby-plugin-image"


const ContainerTreatmentItem = styled.article `
    max-width: 1000px;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(2, auto);
    gap: 1.5rem;
    padding: 1.5rem;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 5px;
    width: 100%;
    -webkit-box-shadow: 0px 3px 6px 3px rgba(0,0,0,0.03); 
    box-shadow: 0px 3px 6px 3px rgba(0,0,0,0.03);

    @media ${device.tablet} {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
    }

`

const ContainerImage = styled.div `
    position: relative;
    display: grid;
    justify-items: center;
    align-items: center;


    .image-styles-hero {

        border-radius: 5px;
        -webkit-border-top-left-radius: 5px;
        -webkit-border-top-right-radius: 5px;
        -moz-border-radius-top-left: 5px;
        -moz-border-radius-top-right: 5px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
`

const Img = styled(GatsbyImage) `
    max-height: 450px;

`


const ContainerText = styled.div `
    display: grid;
    gap: 2rem;
    height: 100%;
    align-content: space-between;

    div {
        display: grid;
        grid-template-rows: auto;
        gap: 1rem;
    }

    p {
        font-size: ${({ theme }) => theme.fonts.size.xs};
    }
`

const ContainerMoreInformations = styled.div `
    display: grid;
    grid-template-rows: auto;
    gap: 1rem;
    position: relative;

    span {
        font-weight: 500;
    }
`

const StyledHeading = styled(Heading) `
    color: ${({ theme }) => theme.colors.green};
    font-weight: 500;
`
const ContainerButtons = styled.div `
    display: grid;
    grid-template-rows: repeat(2, auto);
    justify-content: center;
    gap: 1rem;

    @media ${device.mobileXL} {
        grid-template-columns: repeat(2, auto);
        justify-content: start;
    }
`

const StyledButtonCall = styled.a `
    padding: 0.8rem 1.5rem;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    white-space: nowrap;
    background: ${({isGreen}) => isGreen ? '#2D6A4F' : '#fff'};  
    color: ${({isGreen}) => isGreen ? '#fff' : '#2D6A4F'};  
    border: 1px solid #2D6A4F;
    cursor: pointer;
    font-size: ${({theme}) => theme.fonts.size.xs};
    text-transform: none;
    text-align: center;

    @media ${device.tabletXL} {

        :hover {
            background: ${({isGreen}) => isGreen ? '#fff' : '#2D6A4F'};  
            color: ${({isGreen}) => isGreen ? '#2D6A4F' : '#fff'};
        }
    }

    
`




export const Offer = ({ specialOffer, lodz, bydgoszcz, torun }) => {

    

    const {
        nazwaOferty,
        opisOferty,
        zdjecie,
    } = specialOffer


    if (lodz) {
        return (
            <ContainerTreatmentItem>
                <ContainerImage>
                    <Img imgClassName="image-styles-hero" title={zdjecie?.title} alt={zdjecie?.description} image={zdjecie?.gatsbyImageData} />
                </ContainerImage>
                <ContainerText>
                    <div>
                        <StyledHeading h4 as='h4'>{nazwaOferty}</StyledHeading>
                        <div dangerouslySetInnerHTML={ {__html: opisOferty.childMarkdownRemark.html}}></div>
                    </div>
                    <ContainerMoreInformations>
                        <span>Chcesz znać więcej szczegółów?</span>
                        <ContainerButtons>
                            <Button to='/lodz-kontakt'>Napisz do nas!</Button>
                            <StyledButtonCall href="tel:697514234" isGreen>Zadzwoń</StyledButtonCall>
                        </ContainerButtons>
                    </ContainerMoreInformations>
                </ContainerText>
            </ContainerTreatmentItem>
        )
    } else if(torun) {
        return (
            <ContainerTreatmentItem>
                <ContainerImage>
                    <Img imgClassName="image-styles-hero" title={zdjecie?.title} alt={zdjecie?.description} image={zdjecie?.gatsbyImageData} />
                </ContainerImage>
                <ContainerText>
                    <div>
                        <StyledHeading h4 as='h4'>{nazwaOferty}</StyledHeading>
                        <div dangerouslySetInnerHTML={ {__html: opisOferty.childMarkdownRemark.html}}></div>
                    </div>
                    <ContainerMoreInformations>
                        <span>Chcesz znać więcej szczegółów?</span>
                        <ContainerButtons>
                            <Button to='/torun-kontakt'>Napisz do nas!</Button>
                            <StyledButtonCall href="tel:512115113" isGreen>Zadzwoń</StyledButtonCall>
                        </ContainerButtons>
                    </ContainerMoreInformations>
                </ContainerText>
            </ContainerTreatmentItem>
        )
    } else if(bydgoszcz) {
        return (
            <ContainerTreatmentItem>
                <ContainerImage>
                    <Img imgClassName="image-styles-hero" title={zdjecie?.title} alt={zdjecie?.description} image={zdjecie?.gatsbyImageData} />
                </ContainerImage>
                <ContainerText>
                    <div>
                        <StyledHeading h4 as='h4'>{nazwaOferty}</StyledHeading>
                        <div dangerouslySetInnerHTML={ {__html: opisOferty.childMarkdownRemark.html}}></div>
                    </div>
                    <ContainerMoreInformations>
                        <span>Chcesz znać więcej szczegółów?</span>
                        <ContainerButtons>
                            <Button to='/bydgoszcz-kontakt'>Napisz do nas!</Button>
                            <StyledButtonCall href="tel:506610131" isGreen>Zadzwoń</StyledButtonCall>
                        </ContainerButtons>
                    </ContainerMoreInformations>
                </ContainerText>
            </ContainerTreatmentItem>
        )
    }
    
}