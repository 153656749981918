import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"


import { Wrapper } from '../common/wrapper'
import { Container } from '../common/container'
import { Heading } from '../common/heading'
import { StyledLink } from '../common/link'

import { device  } from '../../utils/breakpoints'


import { Offer } from './offer'

const StyledWrapper = styled(Wrapper) `

`

const StyledContainer = styled(Container) `
  grid-template-rows: auto;

  span {
    color: ${({ theme }) => theme.colors.green};
  }
`


const ContainerMostPopular = styled.div `
  display: grid;
  gap: 2rem;
  grid-template-columns: auto;
  justify-items: center;
`

export const SpecialOffer = ({ lodz, bydgoszcz, torun }) => {

    const data = useStaticQuery(graphql`
    
    {
        allContentfulLodzOfertySpecjalny(sort: {fields: kolejnosc}) {
          nodes {
            id
            nazwaOferty
            opisOferty {
              childMarkdownRemark {
                html
              }
            }
            zdjecie {
              gatsbyImageData(layout: CONSTRAINED)
              title
              description
            }
          }
        }
        
        allContentfulBydgoszczOfertySpecjalne(sort: {fields: kolejnosc}) {
            nodes {
              id
              nazwaOferty
              opisOferty {
                childMarkdownRemark {
                  html
                }
              }
              zdjecie {
                gatsbyImageData(layout: CONSTRAINED)
                title
                description
              }
            }
        }

        allContentfulTorunOfertySpecjalne(sort: {fields: kolejnosc}) {
            nodes {
              id
              nazwaOferty
              opisOferty {
                childMarkdownRemark {
                  html
                }
              }
              zdjecie {
                gatsbyImageData(layout: CONSTRAINED)
                title
                description
              }
            }
        }
      }
    `)
    
    const lodzSpecialOffers = data.allContentfulLodzOfertySpecjalny.nodes
    const bydgoszczSpecialOffers = data.allContentfulBydgoszczOfertySpecjalne.nodes
    const torunSpecialOffers = data.allContentfulTorunOfertySpecjalne.nodes

   if(lodz) {
        return (
            <StyledWrapper>
                <StyledContainer>
                    <ContainerMostPopular>
                    {lodzSpecialOffers.map((specialOffer) => {
                        return (
                            <Offer lodz key={specialOffer.id} specialOffer={specialOffer}/>
                        )
                    })}
                    </ContainerMostPopular>
                </StyledContainer>
            </StyledWrapper>
        )
   } else if(bydgoszcz) {
    return (
        <StyledWrapper>
            <StyledContainer>
                <ContainerMostPopular>
                   {bydgoszczSpecialOffers.map((specialOffer) => {
                       return (
                        <Offer bydgoszcz key={specialOffer.id} specialOffer={specialOffer}/>
                       )
                   })}
                </ContainerMostPopular>
            </StyledContainer>
        </StyledWrapper>
    )
   } else if(torun) {
    return (
        <StyledWrapper>
            <StyledContainer>
                <ContainerMostPopular>
                   {torunSpecialOffers.map((specialOffer) => {
                       return (
                        <Offer torun key={specialOffer.id} specialOffer={specialOffer}/>
                       )
                   })}
                </ContainerMostPopular>
            </StyledContainer>
        </StyledWrapper>
    )
   }
   
}

