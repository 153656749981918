import React from 'react'

import Layout from '../components/layout'
import { TitlePage } from '../components/common/titlePage'
import { SpecialOffer } from '../components/specialOffer'
import { useStaticQuery, graphql } from 'gatsby'
import Seo from '../components/seo'



const LodzOfertySpecjalne = () => {

    const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "oferty-specjalne.jpg"}) {
          id
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              
            )
          }
        }
      }
  `)

  return (
        <Layout lodz>
          <Seo
            title="Lodz oferty specjalne - atrakcyjne ceny"
            description="Poznaj nasze aktualne promocje. Endermologia LPG Alliance w pakiecie aż -1000 zł. Zapraszamy!" 
            url="https://bodymedgroup.pl/lodz-oferty-specjalne"
          />
            <TitlePage
              title='Oferty Specjalne' 
              text='Cieszymy się, że z Nami jesteś. Skorzystaj z wyjątkowych promocji w BodyMed Group!' 
              imageTitle={data.file.childImageSharp.gatsbyImageData}
              titleSeoImage="Programmers working in the office" 
              altSeoImage="Programmers working in the office"
            />
            <SpecialOffer lodz/>
        </Layout>
    )
}

export default LodzOfertySpecjalne
